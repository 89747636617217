<template>
  <div>
    <Pane>
      <template v-slot:extra>
        <Draft @select="onSelectDraft" />
      </template>
    </Pane>

    <a-modal :visible="draftVisible" title="保存草稿" @ok="saveDraft" @cancel="cancelDraft">
      <div style="padding: 8px 0">
        <a-input placeholder="请输入草稿标题" v-model="draftTitle" />
      </div>
    </a-modal>

    <a-form :form="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" :colon="false" @submit="handleSubmit">
      <div class="container">
        <div class="header">
          <img src="../../assets/contract-register/icon-info.png" alt="" />
          <span>合同信息</span>
        </div>
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="经办部门">
              <a-select placeholder="" show-search :filter-option="$selectFilterOption" style="width: 100%" v-decorator="[
                                'operationDeptId',
                                { rules: [{ required: true, message: '请选择！' }] },
                              ]">
                <a-select-option v-for="item in organizationList" :key="item.id" :value="item.id">{{ item.uniqueName
                                  }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">经办人</span>
              <EmployeeSelector @change="(arr) => {
                                if (arr.length > 0) {
                                  selectedUser = arr[0];
                                } else {
                                  selectedUser = {};
                                }
                              }
                              " :value="selectedUser.name ? [selectedUser] : []">
                <a-button block style="text-align: left">
                  <div v-if="selectedUser.name">
                    {{ selectedUser.name }}
                  </div>
                  <div v-else>&nbsp;</div>
                </a-button>
              </EmployeeSelector>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="合同名称">
              <a-input v-decorator="[
                                'name',
                                { rules: [{ required: true, message: '请输入！' }] },
                              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">合同方</span>
              <CompanySelector @change="(company) => (selectedCompany = company)">
                <a-button block style="margin-top: 4px; text-align: left" class="ellipsis">
                  {{ selectedCompany.name }}
                </a-button>
              </CompanySelector>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="所属区域">
              <a-cascader placeholder="" v-decorator="[
                                'area',
                              ]" :options="areaList" :fieldNames="{
                      label: 'areaName',
                      value: 'key',
                      children: 'children',
                    }" />
              <div class="tip">请正确选择区域，具体分布详见表单下方说明</div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="是否有金额或费用比例">
              <a-radio-group v-decorator="[
                                'isKjContractExpense',
                                { rules: [{ required: true, message: '请选择！' }] },
                              ]">
                <a-radio :value="1">是</a-radio>
                <a-radio :value="0">否</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="用章名称" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <a-checkbox-group v-decorator="[
                                'sealType',
                                { rules: [{ required: true, message: '请选择！' }] },
                              ]">
                <a-checkbox v-for="item in sealTypeList" :key="item.value" :value="item.value">
                  {{ item.name }}
                </a-checkbox>
              </a-checkbox-group>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="合同主要内容" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <a-textarea v-decorator="[
                                'remarks',
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: '请输入！',
                                    },
                                  ],
                                },
                              ]" :auto-size="{ minRows: 3 }" />
            </a-form-item>
          </a-col>
        </a-row>
      </div>

      <div class="container">
        <div class="header">
          <img src="../../assets/contract-register/icon-file.png" alt="" />
          <span>附件</span>
          <div class="extra">
            <FileUpload @uploaded="uploaded">
              <a-button type="primary" size="small">上传</a-button>
            </FileUpload>
          </div>
        </div>

        <Padding size="large" />

        <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
          <span class="required" slot="label">附件</span>
          <div style="margin-top: 4px">
            <a-table bordered :dataSource="fileList" :pagination="false">
              <a-table-column title="附件名称">
                <template slot-scope="text">
                  <a :href="text.completePath" :download="$getFileName(text.completePath)" target="_blank">
                    {{ $getFileName(text.completePath) }}
                  </a>
                </template>
              </a-table-column>
              <a-table-column title="操作" align="center" width="120px">
                <template slot-scope="text, record, index">
                  <a href="#" class="danger" @click.prevent="removeFile(index)">删除</a>
                </template>
              </a-table-column>
            </a-table>
          </div>
        </a-form-item>

        <Padding size="large" />
      </div>

      <div class="center">
        <a-space>
          <a-button @click="addDraft">存草稿</a-button>
          <a-button type="primary" htmlType="submit" :loading="saveLoading">
            {{ id ? "重新提交审批" : "提交审批" }}
          </a-button>
          <a-button @click="$close($route.path)">关闭</a-button>
        </a-space>
      </div>
    </a-form>

    <ExtraInfo />

    <CommentModal :visible="commentVisible" @setVisible="setCommentVisible" @done="done" />
  </div>
</template>

<script>
import area from "@/mixins/area";
import organization from "@/mixins/organization";
import watermark from "@/mixins/watermark";
import draft from "@/mixins/draft";

import { add, fetchDetail, edit } from "@/api/framework-contract-register";
import { mapGetters, mapState } from "vuex";

import ExtraInfo from "./components/extra-info";
import EmployeeSelector from "@/components/employee-selector";
import CompanySelector from "@/components/company-selector";
import FileUpload from "@/components/file-upload";
import CommentModal from "@/components/comment-modal";

import moment from "moment";

export default {
  name: "addFrameworkContractRegister",
  mixins: [area, organization, watermark, draft],

  components: {
    ExtraInfo,
    EmployeeSelector,
    CompanySelector,
    FileUpload,
    CommentModal,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      selectedUser: {}, // 选择的经办人
      selectedCompany: {}, // 选择的合同方

      fileList: [], // 附件列表

      saveLoading: false,
      id: null, // 重新提交时的 ID

      commentVisible: false, // 审批意见弹窗
      storageValue: {}, // 暂存的数据
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    sealTypeList() {
      return this.findDataDict("sealType");
    },

    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["department"]),
  },

  activated() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;

      fetchDetail({ id }).then((res) => {
        if (res) {
          // 经办
          this.selectedUser = {
            userId: res.operatorId,
            name: res.operatorName,
          };

          // 合同方
          this.selectedCompany.id = res.partyAid;
          this.selectedCompany.name = res.partyAname;

          const area = [];
          if (res.areaId) {
            area.push(res.areaId);
          }
          if (res.subAreaId) {
            area.push(res.subAreaId);
          }
          if (res.l3AreaId) {
            area.push(res.l3AreaId);
          }

          this.form.setFieldsValue({
            // 经办
            operationDeptId: res.operationDeptId,

            // 合同
            name: res.name,
            remarks: res.remarks,

            // 用章名称
            sealType: res.sealType?.split(","),

            // 所属区域
            area,

            // 是否有金额或费用比例
            isKjContractExpense: res.isKjContractExpense,
          });

          this.fileList =
            typeof res.attachments === "string"
              ? res.attachments.split(",").map((item) => {
                  return {
                    completePath: item,
                  };
                })
              : [];
        }
      });

      return;
    }

    if (!id) {
      if (!this.selectedUser.name) {
        this.selectedUser = {
          userId: this.user.uuid,
          name: this.user.userName,
        };

        this.form.setFieldsValue({
          operationDeptId: this.department.deptId,
        });
      }
    }
  },

  methods: {
    // 上传附件
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    removeFile(index) {
      this.fileList.splice(index, 1);
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values);

          if (!this.selectedCompany.id) {
            this.$message.error("请选择合同方！");
            return;
          }

          if (!this.fileList.length) {
            this.$message.error("请上传附件！");
            return;
          }

          let area1 = {};
          let area2 = {};
          let area3 = {};

          if (Array.isArray(values.area)) {
            const [areaId1, areaId2, areaId3] = values.area;
            area1 = this.rawAreaList.find((item) => item.id === areaId1);
            area2 = this.rawAreaList.find((item) => item.id === areaId2);
            area3 = this.rawAreaList.find((item) => item.id === areaId3);
          }

          const params = {
            // 经办
            operationDeptId: values.operationDeptId,
            operationDeptName: this.organizationList.find(
              (item) => item.id === values.operationDeptId
            )?.uniqueName,
            operatorId: this.selectedUser.userId,
            operatorName: this.selectedUser.name,

            // 合同
            name: values.name,
            remarks: values.remarks,

            // 合同方
            partyAid: this.selectedCompany.id,
            partyAname: this.selectedCompany.name,

            // 用章名称
            sealType: values.sealType.join(),

            // 所属区域
            areaId: area1?.id,
            areaName: area1?.areaName,
            subAreaId: area2?.id,
            subAreaName: area2?.areaName,
            l3AreaId: area3?.id,
            l3AreaName: area3?.areaName,

            // 是否有金额或费用比例
            isKjContractExpense: values.isKjContractExpense,

            attachments: this.fileList.map((item) => item.completePath).join(),
          };

          this.storageValue = params;
          this.commentVisible = true;
          this.saveLoading = true;
        }
      });
    },

    // 审批意见弹窗设置
    setCommentVisible(visible) {
      this.commentVisible = visible;
      this.saveLoading = false;
    },

    done(comment) {
      this.saveLoading = true;
      if (this.id) {
        edit({
          ...this.storageValue,
          id: this.id,
          comment,
        })
          .then((res) => {
            console.log("res", res);
            this.$close(this.$route.path);
          })
          .finally(() => {
            this.commentVisible = false;
            this.saveLoading = false;
          });
      } else {
        add({
          ...this.storageValue,
          comment,
        })
          .then((res) => {
            console.log("res", res);
            this.$close(this.$route.path);
          })
          .finally(() => {
            this.commentVisible = false;
            this.saveLoading = false;
          });
      }
    },

    saveDraft() {
      this.updateDraft({
        id: this.draftId ? this.draftId : undefined,
        title: this.draftTitle + " " + moment().format("YYYY-MM-DD"),
        type: this.draftType,
        content: JSON.stringify({
          values: this.form.getFieldsValue(),
          selectedUser: this.selectedUser,
          selectedCompany: this.selectedCompany,
          fileList: this.fileList,
        }),
      }).then(() => {
        this.draftVisible = false;
        this.draftTitle = "";
      });
    },

    onSelectDraft(draft) {
      if (draft.id && draft.content) {
        this.draftId = draft.id; // 暂存使用的草稿 id
        this.draftTitle =
          typeof draft.title === "string" ? draft.title.split(" ")[0] : "";

        const res = JSON.parse(draft.content);

        this.form.setFieldsValue({
          ...res.values,
        });
        this.selectedUser = res.selectedUser;
        this.selectedCompany = res.selectedCompany;
        this.fileList = res.fileList;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;

  .header {
    display: flex;
    align-items: center;
    padding: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #f0f0f0;
    color: #1890ff;
    font-weight: bold;
    font-size: 14px;

    img {
      width: 14px;
      margin-right: 4px;
    }

    .extra {
      margin-left: auto;
    }
  }

  .tip {
    color: #999;
  }
}

.center {
  margin-top: 80px;
  margin-bottom: 80px;
}
</style>
